/* ==========================================================================
   Anpassungen für Bildungsregion-Emsland.de
   ========================================================================== */

.footer__nav a {
  color: #fff;
  text-decoration: underline;
}

.footer__link a {
  text-decoration: none!important;
}

.list-unstyled li {
  padding: 0!important;
  margin-left: 0!important;
}

.search-list__item {
  margin-bottom: 20px!important;
}

@media (max-width: 767px) {
  .footer-logo {
    width: 250px;
    margin-bottom: 20px;
  }
}

@media (min-width: 376px) {

  img[align="right"] {
    padding-left: 10px;
  }

  img[align="left"] {
    padding-right: 10px;
  }

}

.content-container img {
  max-width: 100%;
}

.member-selection {
  margin-top: 39px;
}

.member-selection__item {
  background-color: #007cc1;
  display: block;
  text-align: center;
  padding: 8px;
  border-bottom: 5px solid #a1c0d5;
  margin-bottom: 20px;
}

.member-selection__item:hover {
  border-bottom: 5px solid #50535a;
}