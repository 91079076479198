@import url("http://fast.fonts.net/t/1.css?apiType=css&projectid=54cf63d8-e76b-4f26-9fff-d6e952c2ba59");
@font-face{
  font-family:"Hybrea W00 UltraLight";
  src:url("../fonts/5bbf6ee3-c1cf-4f43-a519-3e680c158e32.eot?#iefix");
  src:url("../fonts/5bbf6ee3-c1cf-4f43-a519-3e680c158e32.eot?#iefix") format("eot"),url("../fonts/ee0a9f88-38b6-448c-9dff-3b8de99cf89d.woff2") format("woff2"),url("../fonts/9f2ca805-cfdb-455e-af01-8cfceb03222f.woff") format("woff"),url("../fonts/8c2646db-c7c1-44be-828e-e18b3d051744.ttf") format("truetype"),url("../fonts/1d57deca-79a3-442d-83ac-28ecd5b878d4.svg#1d57deca-79a3-442d-83ac-28ecd5b878d4") format("svg");
}
@font-face{
  font-family:"Hybrea W00 ExtraLight";
  src:url("../fonts/b0fd280d-80a9-4f8c-9f3b-7dcccc4dcef6.eot?#iefix");
  src:url("../fonts/b0fd280d-80a9-4f8c-9f3b-7dcccc4dcef6.eot?#iefix") format("eot"),url("../fonts/69526314-1a9d-45a9-a4ee-4a31c4a8d029.woff2") format("woff2"),url("../fonts/31cc1427-b11e-4659-8822-45b643ef04a0.woff") format("woff"),url("../fonts/6282721b-34d5-403d-9714-3934be484980.ttf") format("truetype"),url("../fonts/d86b83f1-4fe4-4a60-9678-2f6b6ac8eab0.svg#d86b83f1-4fe4-4a60-9678-2f6b6ac8eab0") format("svg");
}
@font-face{
  font-family:"Hybrea W00 Light";
  src:url("../fonts/8ed00dfa-b01e-428c-86a5-05141c5ebea2.eot?#iefix");
  src:url("../fonts/8ed00dfa-b01e-428c-86a5-05141c5ebea2.eot?#iefix") format("eot"),url("../fonts/de7e2ace-1652-4db4-887c-1c7841e3ae62.woff2") format("woff2"),url("../fonts/31ff3fd4-1ad1-4b3a-8a49-808171478cc3.woff") format("woff"),url("../fonts/deebd2af-e3a0-483c-a717-a342999bc446.ttf") format("truetype"),url("../fonts/143b77d0-b56c-47d9-932f-f1f875bbbd09.svg#143b77d0-b56c-47d9-932f-f1f875bbbd09") format("svg");
}
/*@font-face{
  font-family:"Hybrea W00 Regular";
  src:url("../fonts/bbd1563a-2728-451c-b4ec-12acfae7e254.eot?#iefix");
  src:url("../fonts/bbd1563a-2728-451c-b4ec-12acfae7e254.eot?#iefix") format("eot"),url("../fonts/22c5cae1-0e4e-42ae-8fda-9b5b4720450d.woff2") format("woff2"),url("../fonts/d2c39efa-98d9-46d4-a414-96515e8b4b0b.woff") format("woff"),url("../fonts/97fa4987-0a72-4ea1-9632-5cf949116239.ttf") format("truetype"),url("../fonts/14fc73b4-b386-424e-b73b-8eda267e38ee.svg#14fc73b4-b386-424e-b73b-8eda267e38ee") format("svg");
}*/
@font-face{
  font-family:"Hybrea W00 Regular";
  src:url("../fonts/hybrea_regular-webfont.woff2") format("woff2"),url("../fonts/hybrea_regular-webfont.woff") format("woff");
}
@font-face{
  font-family:"Hybrea W00 Bold";
  src:url("../fonts/484aa6db-bdec-426b-9648-723050f0c24e.eot?#iefix");
  src:url("../fonts/484aa6db-bdec-426b-9648-723050f0c24e.eot?#iefix") format("eot"),url("../fonts/e18e9121-4f8a-40c7-afca-2aceeed0ea95.woff2") format("woff2"),url("../fonts/804bb331-56bb-49bf-bc6b-f6de122b7438.woff") format("woff"),url("../fonts/f7edb7eb-a297-4f25-8175-71204fe04c34.ttf") format("truetype"),url("../fonts/a7f0e3f8-4c21-452b-a938-988aca668b5f.svg#a7f0e3f8-4c21-452b-a938-988aca668b5f") format("svg");
}
@font-face{
  font-family:"Hybrea W00 Fat";
  src:url("../fonts/c74f81ee-d0b9-428a-84ce-58fa30b1a2ad.eot?#iefix");
  src:url("../fonts/c74f81ee-d0b9-428a-84ce-58fa30b1a2ad.eot?#iefix") format("eot"),url("../fonts/5f4b2bbc-28cf-4806-85ce-a87402619d5c.woff2") format("woff2"),url("../fonts/dff0d428-ee6b-483b-b0be-6197197753b2.woff") format("woff"),url("../fonts/66a63439-b5fe-4203-aade-9ccbf861ac12.ttf") format("truetype"),url("../fonts/827f92a3-e6e5-446a-b1c3-f22c2f85e9ca.svg#827f92a3-e6e5-446a-b1c3-f22c2f85e9ca") format("svg");
}
@font-face{
  font-family:"Hybrea W00 UltraBlack";
  src:url("../fonts/0237e23a-def2-4a4d-b722-d045266f7049.eot?#iefix");
  src:url("../fonts/0237e23a-def2-4a4d-b722-d045266f7049.eot?#iefix") format("eot"),url("../fonts/1d8be455-5856-4f5f-8d0d-ab486d865524.woff2") format("woff2"),url("../fonts/759f4821-7a91-4ad4-8963-43ca8759c03f.woff") format("woff"),url("../fonts/0b4be274-e000-424b-b0a3-8d47755c814c.ttf") format("truetype"),url("../fonts/1c987161-a342-4488-a89f-cb2da44f34a2.svg#1c987161-a342-4488-a89f-cb2da44f34a2") format("svg");
}
@font-face{
  font-family:"Hybrea W00 Dirty";
  src:url("../fonts/815a97b3-f9d6-4481-96bb-867a48037dd7.eot?#iefix");
  src:url("../fonts/815a97b3-f9d6-4481-96bb-867a48037dd7.eot?#iefix") format("eot"),url("../fonts/47e8efd3-19f4-493d-b023-babc9af9908a.woff2") format("woff2"),url("../fonts/f4a9a5d0-1230-469a-a6bd-6ed2bff6b10f.woff") format("woff"),url("../fonts/1bf9e40a-52ae-4232-aa81-04b5516985d5.ttf") format("truetype"),url("../fonts/158b24be-f26a-46d7-a05f-e6b5a76a2586.svg#158b24be-f26a-46d7-a05f-e6b5a76a2586") format("svg");
}

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/open-sans-v15-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
  url('../fonts/open-sans-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/open-sans-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/open-sans-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/open-sans-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/open-sans-v15-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}