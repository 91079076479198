/* ==========================================================================
   Basic connectiv (WebCMS) Styles
   ========================================================================== */

.ir { display: block; text-indent: -999em; overflow: hidden; background-repeat: no-repeat; text-align: left; direction: ltr; }
.no-display, .hidden { display: none; visibility: hidden; }
.visuallyhidden { border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px; }
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus { clip: auto; height: auto; margin: 0; overflow: visible; position: static; width: auto; }
.invisible { visibility: hidden; }
.clearfix:before, .clearfix:after { content: "\0020"; display: block; height: 0; overflow: hidden; }
.clearfix:after { clear: both; }
.clearfix { zoom: 1; }

/* Direct-Edit-Overlay */
.directedit {
  z-index: @zIndex-10;
}

p {
  margin: 0;
}

body#tinymce {
  margin-bottom: 0;
}

#overDiv table {
  background-color: #2D6BB4!important;
  td {
    padding: 1px!important;
  }
  table {
    background-color: #DCEDFE!important;
    td {
      line-height: 14px!important;
      padding: 6px!important;
    }
  }
}

/* Standard Styles */

.clearer {
  clear: both;
}

.left {
  float: left;
}

.right {
  float: right;
}

#sitemap .ebeneTop {
  font-weight: bold;
  font-size: 14px;
  margin: 10px 0 5px 0;
  border-bottom: 1px #e2e2e2 solid;
}

#sitemap div a,
#sitemap div div {
  display: block;
  //border-bottom: 1px #e2e2e2 solid;
  padding: 5px 3px 5px 0;
  font-weight: normal;
}

.eebene:not(a) {
  color: #007cc1;
}

#sitemap div .eebene {
  display: block;
  padding: 5px 3px 5px 10px;
  font-weight: bold;
  border-bottom: 1px #e2e2e2 solid;
}

.content__textbox ul ul, .content ul ul, .mceContentBody ul ul{
  margin: 0 0 0 15px;
  padding: 0;
  list-style-type: none;
}

.content__textbox ul ul ul, .content ul ul ul, .mceContentBody ul ul ul {
  margin: 0 0 0 30px;
  padding: 0;
  list-style-type: none;
}

.content__textbox ul ul ul ul, .content ul ul ul ul, .mceContentBody ul ul ul ul {
  margin: 0 0 0 45px;
  padding: 0;
  list-style-type: none;
}

.content__textbox  ul ul ul ul ul, .content ul ul ul ul ul, .mceContentBody ul ul ul ul ul {
  margin: 0 0 0 60px;
  padding: 0;
  list-style-type: none;
}

.content__textbox ul ul ul ul ul ul, .content ul ul ul ul ul ul, .mceContentBody ul ul ul ul ul ul {
  margin: 0 0 0 75px;
  padding: 0;
  list-style-type: none;
}

.content__textbox ol, .content ol, .mceContentBody ol {
  margin-left: 15px;
  padding: 0;
}

.content__textbox ul, .content ul, .mceContentBody ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.content__textbox ol li {
  position: relative;
  font-size: 14px;
  padding: 0;
  margin: 0 0 5px 0;
}

.content__textbox ul li {
  position: relative;
  font-size: 14px;
  padding: 0 0 0 12px;
  margin: 0 0 5px 0;
}

.content__textbox ul li, .content ul li, .mceContentBody ul li {
  &::before {
    background-color: transparent;
    content: '\f0da';
    font: normal normal normal 14px/1 FontAwesome;
    /*font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate(0,0);
    -ms-transform: translate(0,0);
    transform: translate(0,0);*/
    margin-left: -10px;
    top: 2px;
    position: absolute;
  }
  //Square
  /*&:before {
    content: '';
    background-color: @brand-primary;
    display: block;
    width: 6px;
    height: 6px;
    margin-left: -15px;
    position: absolute;
    top: 6px;
  }*/
  //Arrow
  /*&:before {
    content: '';
    border-style: solid;
    border-width: 6px 0 6px 9px;
    border-color: transparent transparent transparent @brand-primary;
    position: absolute;
    left: 0;
    top: 5px;
  }*/
}

#content .pagination li:before {
  background: none;
  display: none;
}

#captcha {
  background-image: url("../../captcha.php");
  width: 100px;
}

/* !!! WebCMS-Teil / WYSIWYG !!! ANFANG */

.directedit {
  position: absolute;
  right: 0px;
  top: 0px;
}

#wysiwyg {
}

.content {
  padding: 10px;
  background-image: none !important;
  background-color: #fff !important;
}

.randleiste_1 {
  background-image: none;
  background-color: #fff !important;
}


/* !!! WebCMS-Teil / WYSIWYG !!! ENDE */

/**
* Templates
*/
.image-description {
  background-color: transparent;
  color: #000;
  padding: 5px;
}

@media (max-width: 767px) {
  .img_left {
    border: 0 none;
    margin: 0 auto 20px auto;
    display: block;
  }

  .img_right {
    border: 0 none;
    margin: 0 auto 20px auto;
    display: block;
  }
}

@media (min-width: 768px) {

  .img_left {
    border: 0 none;
    float: left;
    margin: 0 20px 0 0;
  }

  .img_right {
    border: 0 none;
    float: right;
    margin: 0 0 0 20px;
  }

}

/* lightbox */

#fancybox-title,#fancybox-title-over {
  text-align: left;
  position: relative;
}

#fancybox-title span,#fancybox-title-over span {
  display: block;
}

#fancybox-title span.title,#fancybox-title-over span.title {
  font-weight: bold;
  font-size: 14px;
}


/* MessageStack */
#error {
  background-color: #ffe4e4;
  background-image: url(../../images/admin/error.jpg);
  background-position: 8px 8px;
  background-repeat: no-repeat;
  border: 1px solid #ff0000;
  padding: 10px 10px 10px 55px;
  color: #ff0000;
  margin-bottom: 15px;
}

#ok {
  background-color: #cbffc4;
  background-image: url(../../images/admin/ok.jpg);
  background-position: 8px 8px;
  background-repeat: no-repeat;
  border: 1px solid #3f9519;
  padding: 10px 10px 10px 55px;
  margin-bottom: 15px;
  color: #3f9519;
}

#info {
  background-color: #edf8fe;
  background-image: url(../../images/admin/info.jpg);
  background-position: 8px 8px;
  background-repeat: no-repeat;
  border: 1px solid #2d6bb4;
  padding: 10px 10px 10px 55px;
  color: #2d6bb4;
  margin-bottom: 15px;
}

#content #ok,
#content #info,
#content #error {
  background: none;
  background-color: #CCC;
  color: #000;
  padding: 10px;
  border: none;
}

#info li, #ok li, #error li {
  list-style: none;
}

.marquee {
  display: block;
  width: 100%;
}

.marquee > div {
  overflow: hidden;
}

.marquee .content-container {
  white-space: nowrap;
}

.marquee .content-container div {
  display: inline;
}


#gallery-list-right,
#gallery-list-left {
  cursor: pointer;
}

#gallery-list-right.disabled,
#gallery-list-left.disabled {
  cursor: default;
}
.divInfo .sortable div.drag {
  float: right !important;
}

.pdflink,
.doclink,
.pptlink,
.xlslink,
.jpglink {
  padding-left: 25px;
  padding-top: 1px;
  background-repeat: no-repeat;
  margin-bottom: 5px;
}

.pdflink {
  background-image: url('../../images/download_icons/pdf_files.gif');
}

.doclink {
  background-image: url('../../images/download_icons/word_files.gif');
}

.pptlink {
  background-image: url('../../images/download_icons/power_files.gif');
}

.xlslink {
  background-image: url('../../images/download_icons/excel_files.gif');
}

.jpglink {
  background-image: url('../../images/download_icons/jpg_files.gif');
}